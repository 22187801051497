<template>
  <div class="modal-wrapper force-to-close" @click="closeModal">
    <div class="modal-content">
      <div :class="titleBgColorClass">
        <a class="modal-close force-to-close" @click="closeModal">
          <VueIcon
            class="force-to-close"
            iconName="IconCross"
            iconColor="#FFF"
            :width="getIcon.cross.width"
            :height="getIcon.cross.height"
          ></VueIcon>
        </a>
        <VueText v-if="title" sizeLevel="7" weightLevel="3" color="white-100">{{ title }}</VueText>
      </div>
      <div class="modal-content-wrapper modal-content-center">
        <div>
          <slot></slot>
        </div>
      </div>
      <BrandButton
        as="a"
        :size="sizes.xxLarge"
        class="force-to-close"
        id="modalCloseBtn"
        @click="closeModal"
        >{{ modalConfig.buttonText }}</BrandButton
      >
    </div>
  </div>
</template>
<script>
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';

export default {
  name: 'BrandInfoModal',
  components: {
    VueIcon,
    VueText,
    BrandButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    headerClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modalConfig: {
        contentAlignment: FLEX_JUSTIFIES.center,
        textAlignment: FLEX_JUSTIFIES.center,
        buttonText: 'TAMAM',
      },
    };
  },
  computed: {
    getIcon() {
      const { apply, cross, warn } = ICON_VARIABLES;
      return { cross, warn, apply };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    titleBgColorClass() {
      return this.headerClass ? `modal-header ${this.headerClass}` : `modal-header`;
    },
  },
  created() {
    document.body.style.overflow = 'hidden';
  },
  methods: {
    closeModal(e) {
      if (e.target.classList.contains('force-to-close')) {
        this.$emit('closed', true);
        document.body.style.overflow = 'unset';
      }
    },
  },
};
</script>
<style scoped lang="scss">
.modal-header {
  position: relative;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding: palette-space-level('15') palette-space-level('20');
  width: 100%;
  background-color: palette-color-level('grey', '40');
}
.bg-cover-blue {
  background-image: url('~@/assets/bg/info-card-bg.png');
  background-color: palette-color-level('blue', '40');
}
.bg-cover-yellow {
  background-image: url('~@/assets/bg/info-card-bg.png');
  background-color: palette-color-level('yellow', '30');
}
.bg-cover-red {
  background-image: url('~@/assets/bg/info-card-bg.png');
  background-color: palette-color-level('fushia', '20');
}
.bg-cover-peach {
  background-image: url('~@/assets/bg/info-card-bg.png');
  background-color: palette-color-level('pink', '10');
}
.bg-cover-orange {
  background-image: url('~@/assets/bg/info-card-bg.png');
  background-color: palette-color-level('orange', '10');
}
.modal {
  &-wrapper {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: palette-space-level('20');
    z-index: 40;
    top: 0;
    left: 0;
    object-fit: contain;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.7);
  }
  &-content {
    width: 650px;
    overflow: hidden; // Alt radius gorunmemesi durumu icin
    margin: auto;
    z-index: 1;
    background-color: palette-color-level('white', '100');
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    word-break: break-word;

    &-text {
      &-center {
        text-align: center;
      }
    }
    &-wrapper {
      display: block;
      padding: palette-space-level('20');
      max-height: 60vh;
      overflow-y: auto;

      &-nospace {
        padding: 0 !important;
      }
    }
    @each $variation, $alignment in $palette-flex-alignments {
      &-#{$alignment} {
        align-items: $alignment;
      }
    }
    &-icon {
      padding: 20px;
    }

    &-pdf {
      canvas {
        max-width: 100%;
      }
    }
  }
  &-close {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 20px;
  }
  &-button-wrapper {
    display: flex;
    padding: 2px;
  }
  &-title {
    font-size: palette-font-size-level(9);
    font-weight: palette-font-weight(4);
  }
}
.modal-close {
  display: block;
}
</style>
