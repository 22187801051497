<template>
  <div id="divOynaKazanWrapper" class="content-layout-fixer">
    <div class="container play-win">
      <div class="wrapper">
        <BrandInfoCard
          :key="i"
          v-for="(marathon, i) in activeMarathons"
          :title="marathon.marathonName"
          sizeLevel="11"
          :iframeGame="marathon.marathonId === -1"
          :bg-color="marathon.bg"
          :marathon="marathon"
          @click="showInfo(marathon)"
        />
      </div>
      <VueText
        v-if="showDailyMarathonMessage"
        style="padding: 30px 0;"
        sizeLevel="8"
        weightLevel="3"
        >{{ dailyMarathonMessage }}</VueText
      >
      <transition name="fade">
        <BrandInfoModal
          class="marathon-modal-wrapper"
          v-if="modal.show"
          :title="modal.title"
          :headerClass="modal.headerClass"
          @closed="closedModal"
        >
          <div class="static-desc" v-html="modal.description"></div>
          <div class="scores-container" v-if="modal.points && modal.points.length > 0">
            <VueText class="scores-title" sizeLevel="6" weightLevel="4"
              >Geçmiş Oyun Sonuçları</VueText
            >
            <div class="scores-table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Oyun</th>
                    <th>Oyun Tarihi</th>
                    <th>Sıralama</th>
                    <th>Kazanılan Puan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in modal.points" :key="`key_${i}`">
                    <td>{{ item.description }}</td>
                    <td>{{ item.date | parseISODate }}</td>
                    <td>{{ item.rank }}</td>
                    <td>{{ item.exchangePoints | currencyFormat }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </BrandInfoModal>
      </transition>
    </div>

    <div v-if="chefShopAvailable" class="virtual-visit">
      <span>
        Geçen ay tüm uygulamalardan kaç puan <br />
        &nbsp;&nbsp;&nbsp;&nbsp;kazandığını öğrenmek ister misin? <br />
        <br />
      </span>
      <span> Cevabı için <b style="color:red">Şef Dükkanında</b> videonu hemen izle !</span>

      <BrandButton
        class="chefs-shop-button"
        as="router-link"
        :target="chefsShopURL"
        @click.native="virtualVisitDataLayer"
      >
        <VueIcon
          class="virtual-visit-icon"
          :iconName="constants.icons.virtualVisit.name"
          :width="constants.icons.virtualVisit.width"
          :height="constants.icons.virtualVisit.height"
          :iconColor="constants.icons.virtualVisit.color"
          :iconSize="{
            width: constants.icons.eorder.width,
            height: constants.icons.eorder.height,
          }"
        />
        <VueText color="grey-40" sizeLevel="7" weightLevel="5">Şef Dükkanında</VueText></BrandButton
      >
    </div>
  </div>
</template>
<script>
import BrandInfoCard from '@/components/brand/BrandInfoCard/BrandInfoCard.vue';
import BrandInfoModal from '@/components/brand/Modals/BrandModal/BrandInfoModal.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon';
import StaticContent from '@/services/Api/staticContents';
import GameApi from '@/services/Api/gamification';
import dateUtils from '@/mixins/dateUtils';
import currencyFilter from '@/mixins/currencyFilter.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'PlayWin',
  mixins: [dateUtils, currencyFilter, gtmUtils],
  data() {
    return {
      activeMarathons: [],
      showDailyMarathonMessage: false,
      dailyMarathonMessage: '',
      chefShopAvailable: false,
      chefsShopURL: `${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`,
      modal: { show: false, title: '', scores: null, points: [] },
    };
  },

  methods: {
    virtualVisitDataLayer() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('sef_dukkaninda', {
        location: 'oyna_kazan',
      });
    },
    checkCampaigns() {
      let cachedMenuData = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_MENU_ITEMS,
      }).get();
      cachedMenuData?.menuItems?.forEach(element => {
        element.children?.forEach(children => {
          if (
            children.name === 'Şef Dükkanında Özetim' ||
            children.id === 19 ||
            children.screenCode === 18
          ) {
            this.chefShopAvailable = true;
          }
        });
      });
    },
    showInfo(game) {
      this.modal.points = [];
      StaticContent.getStaticContent(game.marathonCode).then(res1 => {
        let {
          Data: { content },
        } = res1.data;
        if (game.marathonId !== -1 && game.marathonGameType != 20) {
          GameApi.getScores(game.marathonGameType).then(res2 => {
            let {
              Data: { points },
            } = res2.data;
            this.modal.points = points;
          });
        }
        this.modal.description = content;
        this.modal.show = true;
        this.modal.title = game.marathonName;
        this.modal.headerClass = 'bg-cover-' + game.bg;
      });
      this.pushDataLayerEvent('competition', {
        name: game.marathonName,
        action: 'click',
      });
    },
    closedModal() {
      this.modal.show = false;
    },
    getDailyMarathonMessage() {
      StaticContent.getStaticContent('EmptyMarathonErrorMessage').then(res => {
        let {
          Data: { content },
        } = res.data;

        this.dailyMarathonMessage = content;
      });
    },
    async getCheckActiveMarathons() {
      const result = await GameApi.checkActiveMarathons();
      this.showDailyMarathonMessage = result?.data?.Data.marathon.length === 0;

      const marathonIdColors = {
        '1008': 'yellow',
        '1009': 'red',
        '1010': 'peach',
      };

      this.activeMarathons =
        result?.data?.Data?.marathon?.map(f => ({
          ...f,
          bg: marathonIdColors[f.marathonId] || 'orange',
        })) || [];

      if (this.$route.query.info) {
        let index = this.activeMarathons.findIndex(x =>
          x.marathonCode.toLowerCase().includes(this.$route.query.info.toLowerCase()),
        );
        if (index > -1) {
          this.showInfo(this.activeMarathons[index]);
        }
      }
    },
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        icons: {
          eorder: { ...ICON_VARIABLES.eorder, color: '#ffffff' },
          virtualVisit: { ...ICON_VARIABLES.virtualVisit, color: '#d02d2c' },
        },
      };
    },
  },
  components: {
    BrandInfoCard,
    BrandInfoModal,
    BrandButton,
    VueText,
    VueIcon,
  },
  beforeMount() {
    this.$store.dispatch('app/setRefreshMarathonBanner', true);

    this.getCheckActiveMarathons();
    this.getDailyMarathonMessage();
  },
  created() {
    this.$store.dispatch('app/setOverlayFunction', false);
    this.checkCampaigns();
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.virtual-visit {
  margin-top: 80px;
  font-size: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.chefs-shop-button {
  width: 30% !important;
  margin-top: 50px;
  border-radius: 5px;
  padding: 1rem;
  font-family: 'Avenir Next';
  font-size: 1rem;
  padding: #000;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    linear-gradient(to left, #d20051, #ffa300);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  .virtual-visit-icon {
    margin-right: palette-space-level(12);
  }
}

.container {
  &.play-win {
    padding-top: palette-space-level(30);
    padding-left: calc(((100% - 1024px) / 2) + #{palette-space-level(20)});
    .wrapper {
      display: grid;
      height: 100%;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: palette-space-level(20);
    }

    .marathon-modal-wrapper {
      .scores-container {
        .scores-title {
          padding-left: palette-space-level(20);
          padding-bottom: palette-space-level(10);
        }
        .scores-table-wrapper {
          max-height: 200px;
          overflow: auto;
          padding: palette-space-level(5);
          table {
            th,
            td {
              border: 1px solid palette-color-level('grey', '20');
            }
            width: 100%;

            border-collapse: collapse;
            thead {
              tr {
                th {
                  background-color: palette-color-level('grey', '10');
                  padding: palette-space-level(10);
                  color: palette-color-level('grey', '40');
                  font-size: 13px;
                }
              }
            }
            tbody {
              tr {
                td {
                  padding: palette-space-level(8);
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.player-wrapper {
  margin-top: 60px;
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  #ommaPlayer {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
